/**
 * Created by fworld on 2019/12/26.
 */
import {Client} from './mqttws31'

let MqttClient = {
  client: null,
  userName: '',
  getNode: () => {
    console.log('please modify Promise')
    return new Promise()
  },
  connect: ({ip, port,  userName, password, isSsl}) => {
    if (MqttClient.client !== null) {
      MqttClient.client.disconnect()
    }
    MqttClient.client = new Client(ip, parseInt(port), '/mqtt', userName)
    MqttClient.client.onConnectionLost = MqttClient.failure
    MqttClient.client.onFailure = MqttClient.failure
    MqttClient.client.onMessageArrived = MqttClient.messageArrived
    MqttClient.client.userName = userName
    MqttClient.client.connect({
      userName: userName,
      password: password,
      timeout: 10,
      cleanSession: true,
      useSSL: isSsl,
      onSuccess: MqttClient.messageConnect
    })
  },
  random: (min, max) => {
    return Math.random().toFixed(3) * (max - min) + min;
  },
  failure: () => {
  },
  messageConnect: () => {
    MqttClient.client.subscribe(MqttClient.client.clientId)

  },
  messageArrived: (message) => {
    console.log('please modify messageArrived')
  },
  messageConnectionLost: function (callback) {
    let _this = MqttClient
    MqttClient.client = null
    _this.getNode().then(res => {
      _this.connect(res.data)
      callback(res.data.userName)
    }).catch((e) => {
      console.log(e)
      _this.failure()
    })
  },
  disconnect: () => {
    if (MqttClient.client !== null) {
      MqttClient.client.disconnect()
    }

  }
}

export default MqttClient
