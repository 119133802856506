import { render, staticRenderFns } from "./findPasswd.vue?vue&type=template&id=dfcfc6ea&scoped=true"
import script from "./findPasswd.vue?vue&type=script&lang=js"
export * from "./findPasswd.vue?vue&type=script&lang=js"
import style0 from "./findPasswd.vue?vue&type=style&index=0&id=dfcfc6ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfcfc6ea",
  null
  
)

export default component.exports