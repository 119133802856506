<template>

  <div class="qrcode">
    <div class="qrcode-container" @click="handleIm">
      <qrcode :value="qrUrl" :options="{ width: 160 }"></qrcode>
      <p class="info" v-html="info" v-show="show"></p>
    </div>
    <span>请用微信或企业微信进行扫码登录</span>
  </div>
</template>
<script>

  import {thirdCompanyIm} from "@/api/work/company";
  import client from '@/assets/js/mqttclient'
  import {setToken, getToken, removeToken} from '@/util/auth'
  import {mapActions} from "vuex";

  export default {
    data() {
      return {
        base: 'http://p.yunka-it.cn/pay/login.html?uuid=6becc3266c01000&webTicket=',
        qrUrl: 'http://p.yunka-it.cn/pay/login.html?uuid=6becc3266c01000&webTicket=',
        info: '二维码已失效<br/>点击刷新',
        show: false,

      };
    },
    methods: {
      ...mapActions(['setEnterpriseMenu', `setEnterpriseEmployee`,
        `setEmployeeCorpid`, 'setSelectMenus']),
      handleIm() {
        client.getNode = thirdCompanyIm
        client.messageArrived = this.messageArrive
        let vue = this
        client.failure = function () {
          vue.show = true
        }
        client.messageConnectionLost((clientId) => {
          vue.qrUrl = vue.base + clientId
          vue.show = false
        })
      },
      messageArrive(message) {
        try {
          let info = JSON.parse(message.payloadString)
          setToken(info.token)
          window.location.href = window.location.href.split("#")[0]

        } catch (e) {
          console.log(e)
        }
      },

    },
    activated() {
      this.handleIm()
    },
    mounted() {
      window.onclose = function () {
        client.disconnect()
      }
    },
    beforeDestroy() {
      client.disconnect()
    },
  };
</script>
<style lang="scss" scoped>
  .qrcode {
    text-align: center;

    &-container {
      position: relative;
      text-align: center;

      .info {
        position: absolute;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        top: 0;
        left: 31.5%;
        width: 160px;
        height: 160px;
        background-color: rgba(0, 0, 0, .6);
        color: #fff;
      }
    }
  }
</style>


