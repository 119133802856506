<template>
  <div class="login">
    <div class="header clearfix">
      <div class="logo">
        <a href="javascript:void(0);">
        </a>
      </div>
      <p>物联助力咖啡业务更高效</p>
    </div>
    <div class="login-section clearfix">

      <div class="banner">
        <img src="../../assets/img/login-bg.png"/>
      </div>
      <div class="user-login" @click.stop.prevent>
        <span class="code" @click="handleLoginSw" :style="!loginByInut?{backgroundPosition: '0 -48px'}:{}"></span>
        <div v-show="loginByInut">
          <h2>用户账号登录</h2>
          <div class="input-row">
            <div class="icon">
              <span class="iconfont  icon-PCchawanwu_zhanghuzhanghao"></span>
            </div>
            <input type="text" placeholder="请输入登录手机" v-model="phone">
          </div>
          <div class="input-row">
            <div class="icon">
              <span class="iconfont  icon-PCchawanwu_shurumima"></span>
            </div>
            <input type="password" placeholder="请输入登录数密码" v-model="password">
            <a href="javascript:void(0);" @click.stop.prevent="handleFindPwd">忘记密码?</a>
          </div>
          <div class="input-row">
            <button @click="goLogin">登录</button>
          </div>
        </div>
        <transition name="fade-transform">
          <div v-show="!loginByInut">
            <h2>扫码登录</h2>
            <qrcode-container ref="qrcode"></qrcode-container>
          </div>
        </transition>

        <p class="tips">
          <!--<span @click="regEnterprise=true" class="register">注册企业</span>-->
          <span class="info">云咖信息系统集成服务©提供云计算服务️</span>
        </p>
      </div>

      <transition name="fade-transform">
        <find-passwd @isOpenReg="resetPwd" v-show="findPwdVisible"></find-passwd>
      </transition>


      <transition name="fade-transform">
        <div class="dialog dialog-forget-pwd" v-show="checkEnterprise" @click.stop.prevent>
          <div class="user-login">
            <h2>选取公司</h2>
            <ul class="list-enterprise">
              <li v-for="(enterprise, index) in enterprises" @click="chooseEnterprise(enterprise)"
                  :key="index">
                {{enterprise.name}}
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import Logo from '@/components/Logo'
  import QrcodeContainer from './components/qrcode';
  import FindPasswd from './components/findPasswd';
  import {
    employeeHasBindEnterprise,
    employeeLogin,
    employeeRefresh,
    employeeGetCode,
    employeeModules
  } from "@/api/account/employee";
  import {checkPassword, checkPhone} from "@/util/checkLogin"
  import {setToken, getToken, removeToken} from '@/util/auth'
  import {mapActions} from 'vuex'
  import {changeTheme} from '@/assets/theme/setTheme.js'

  export default {
    data() {
      return {
        loginByInut: true,
        findPwdVisible: false,
        checkEnterprise: false,
        regEnterprise: false,
        resetPwdVisible: false,
        phone: "", //登录账号
        password: "", //登录密码
        checkCode: "", //登录验证码
        enterprises: [],
        code: '',
        cssColor: '#1678fe',
      }
    },
    mounted: function () {
      changeTheme('light');
      // document.addEventListener("click", () => {
      //   this.findPwdVisible = false
      //   // 重置密码不允许进入登录页面
      //   this.loginByInut = true
      //   this.checkEnterprise = false
      //   this.regEnterprise = false
      // }, false)
      let token = getToken()
      if (token) {
        employeeRefresh({token: getToken}).then(res => {
          this.handleLogin(res)
        })
      }
    },
    activated() {
      changeTheme('light');
    },
    methods: {
      ...mapActions(['setEnterpriseEmployee', 'setEnterpriseMenu', 'setSelectMenus']),
      handleFindPwd() {
        this.findPwdVisible = true
      },
      handleLoginSw() {
        this.loginByInut = !this.loginByInut
        if(!this.loginByInut){
          this.$refs.qrcode.handleIm()
        }
      },
      resetPwd() {
        this.findPwdVisible = false
        this.loginByInut = true
      },

      handleLogin(res) {
        if (res.code === 0) {
          let token = res.data.refreshToken
          this.setEnterpriseEmployee(res.data.employee);
          setToken(token);
          let vue = this
          employeeModules().then(res => {
            if (res.code === 0) {
              res.data = res.data || []
              if (res.data.length === 0 || res.data[0].modules === null) {
                vue.$message.warning('暂无登录权限,请联系管理员开通权限')
                removeToken()
                vue.$router.replace({name: 'login'})
                return
              }
              let showMenus = res.data
              vue.setEnterpriseMenu({
                navMenus: showMenus,
                routerName: this.$route.name,
              })
              vue.setSelectMenus(showMenus[0])
              vue.$router.replace({name: showMenus[0].modules[0].webRouterName})
            }
          })
        }
      },
      goLogin: function () {
        //验证手机号码
        let checkPhoneObj = checkPhone(this.phone);
        if (!checkPhoneObj.status) {
          this.$message.error(checkPhoneObj.msg);
          return;
        }
        //验证密码
        let checkPasswordObj = checkPassword(this.password);
        if (!checkPasswordObj.status) {
          this.$message.error(checkPasswordObj.msg);
          return;
        }

        let vue = this

        employeeGetCode({password: this.password, phone: this.phone}).then(res => {
          if (res.code === 0) {
            let code = res.data;
            vue.code = code
            employeeHasBindEnterprise({code: code}).then(res => {
              if (res.code === 0) {
                vue.enterprises = res.data
                if (res.data.length === 0) {
                  vue.$message.warning('当前账户不允许登录');
                  return
                }
                if (res.data.length === 1) {
                  vue.chooseEnterprise(res.data[0])
                } else {
                  vue.checkEnterprise = true
                }
              } else {
                vue.$message.error(res.msg);
              }
            })
          } else {
            vue.$message.error(res.msg);
          }
        });

      },
      chooseEnterprise(enterprise) {
        const data = {
          code: this.code,
          bid: enterprise.uuid,
        }
        employeeLogin(data).then(res => {
          if (res.code === 0) {
            setToken(JSON.stringify(enterprise), `enterprise`);
            this.handleLogin(res)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    components: {
      Logo,
      QrcodeContainer,
      FindPasswd
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    //position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;

    .header {
      width: 100%;
      background-color: white;
      display: inline-flex;
      border-bottom: 1px solid #f2f2f2;
      visibility: hidden;

      .logo {
        cursor: pointer;
        font-size: 24px;
        font-weight: 700;
        width: $--aside-max-width;
        line-height: $_header_height;
        display: inline-block;
        vertical-align: middle;


        a {
          display: inline-block;
          width: $--aside-min-width;
          /*margin: 10px 8px;*/
          height: 40px;
          background: url('../../assets/img/yunka.png') no-repeat;
          background-size: cover;
          position: relative;
          // top: -3px;
          vertical-align: middle;
        }

        span {
          font-weight: normal;
          font-size: 16px;
          position: relative;
          top: -2px;
          color: $_theme_color;
        }
      }

      p {
        margin-left: 20px;
        font-size: 18px;
        padding-top: 3px;
        color: $_theme_color;
        font-weight: 700;
        line-height: $_header_height;
      }
    }

    .logo {
      margin-left: 100px;
    }

    .login-section {
      width: 1000px;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 20px 0;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, .1);
      border-radius: $border-radius;

      .banner {
        width: 436px;
        height: 436px;
        float: left;
        margin-left: 32px;

        img {
          max-width: 436px;
        }
      }

      .user-login {
        width: 436px;
        height: 436px;
        float: right;
        background-color: #fff;
        position: relative;
        border-left: 1px solid #f2f2f2;

        .code {
          display: block;
          width: 47px;
          height: 47px;
          background: url("../../assets/img/qrflag.jpg") no-repeat;
          position: absolute;
          right: 5px;
          top: 5px;
          cursor: pointer;
        }

        h2 {
          text-align: center;
          font-size: 18px;
          color: #6f6f6f;
          font-weight: 700;
          margin-top: 50px;
          margin-bottom: 30px;
        }

        .list-enterprise {
          text-align: center;
          height: 320px;
          overflow-y: scroll;

          li {
            cursor: pointer;
            line-height: 30px;
            padding: 5px 0;
          }

          li:hover {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            color: $_theme_color;
          }
        }

        .input-row {
          width: 330px;
          margin: 0 auto;
          position: relative;
          margin-bottom: 30px;

          .icon {
            position: absolute;
            font-size: 16px;
            left: 4px;
            line-height: 35px;
            color: $_theme_color;
          }

          input {
            display: block;
            width: 100%;
            border: none;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid $--border-color;
            outline: none;
            color: $--font-02-color;
          }

          a {
            position: absolute;
            font-size: 12px;
            right: 10px;
            top: 10px;
            color: $_theme_color;
          }

          button {
            display: block;
            margin: 0 auto;
            width: 250px;
            height: 45px;
            margin: 0 auto;
            border: none;
            outline: none;
            letter-spacing: 5px;
            background: $_theme_color;
            color: #fff;
            font-size: 14px;
            border-radius: 22.5px;
            cursor: pointer;
            margin-top: 50px;
          }
        }

        .tips {
          width: 100%;
          font-size: 12px;
          position: absolute;
          bottom: 10px;
          margin-bottom: 20px;
          text-align: center;
          box-sizing: border-box;
          color: #999;
          padding: 0 25px;

          .register {
            float: left;
            cursor: pointer;
            color: $_theme_color;
            font-weight: 600;
          }

          .info {
            float: right;
          }
        }
      }

      .qr_code {
        width: 255px;
        height: 255px;
        margin: 0 auto;
      }
    }

    .dialog {
      width: 436px;
      height: 436px;
      background-color: #ffff;
      position: absolute;
      right: 20px;
      border-radius: 20px;
      box-shadow: 20px 0 0 rgba(0, 0, 0, .3);
    }
  }

  @media (max-width: 1366px) {
    .login {
      .logo {
        margin-left: 140px;
      }
    }
  }
</style>
